export const getPhotoUrl = (photoable: string, filename: string, size?: string) => {
  let path = `/photos/${photoable}`;

  if (size) {
    path = `${path}/${size}`;
  }

  path = `${path}/${filename}`;

  return path;
};
